<li [routerLink]="item().path">
	<div
		[class]="isCollapsed() ? 'navigation-list_item-label-collapsed' : 'navigation-list_item-label'"
		[ngClass]="displaySubNavigation ? 'dropdown-active' : ''"
		(click)="openSubNavigation()">
		<div
			class="navigation_item-content"
			[ngClass]="displaySubNavigation ? 'active' : ''"
			[routerLinkActiveOptions]="{ exact: false }"
			routerLinkActive="active">
			@if (item().icon !== null) {
				<fa-icon [icon]="icon"></fa-icon>
			}

			@if (!isCollapsed()) {
				<span>{{ item().label }}</span>
			}

			@if (item().subNavigation && !isCollapsed()) {
				<fa-icon [icon]="stateIcon"></fa-icon>
			}
		</div>

		@if (displaySubNavigation) {
			<div
				intranetClickOutside
				[appOutSideClick]="displaySubNavigation"
				(outSideClick)="displaySubNavigation = false"
				[class]="isCollapsed() ? 'navigation-list_item-sub-navigation-collapsed' : 'navigation-list_item-sub-navigation'">
				<ul>
					@for (subNav of item().subNavigation; track subNav.label) {
						<li
							[routerLink]="subNav.path"
							routerLinkActive="active"
							class="navigation-list_item-sub-navigation_item">
							<a>{{ subNav.label }}</a>
						</li>
					}
				</ul>
			</div>
		}
	</div>
</li>

<ng-template #closed>
	<div
		class="navigation-list_item-label-collapsed"
		[ngClass]="displaySubNavigation ? 'active' : ''"
		(click)="openSubNavigation()"
		routerLinkActive="active">
		@if (item().icon !== null) {
			<fa-icon [icon]="icon"></fa-icon>
		}

		@if (displaySubNavigation) {
			<div class="navigation-list_item-sub-navigation-collapsed">
				<ul>
					@for (subNav of item().subNavigation; track subNav.label) {
						<li
							[routerLink]="subNav.path"
							routerLinkActive="active"
							class="navigation-list_item-sub-navigation_item">
							<a>{{ subNav.label }}</a>
						</li>
					}
				</ul>
			</div>
		}
	</div>
</ng-template>
