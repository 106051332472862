import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { typeIntervention } from '@domain';
import { HasStore } from '@application';
import { TypeInterventionRepository } from '@infrastructure';

export type typeInterventionState = {
	type: Map<number, typeIntervention>;
};

@Injectable({
	providedIn: 'root',
})
export class TypeInterventionManager extends HasStore<typeInterventionState> {
	constructor(private readonly typeInterventionRepository: TypeInterventionRepository) {
		super({ type: new Map() }, 'type-intervention');
	}

	getTypeIntervention() {
		this.typeInterventionRepository
			.getTypeIntervention()
			.pipe(take(1))
			.subscribe(typeIntervention => {
				const mapTypeIntervention = new Map<number, typeIntervention>();

				typeIntervention.forEach(type => {
					mapTypeIntervention.set(type.id, type);
				});

				this.store.update((state: typeInterventionState) => {
					return {
						...state,
						type: mapTypeIntervention,
					};
				});
			});
	}
}
