<div class="access-denied-container">
	<div class="access-denied-card">
		<div class="access-denied-card-image">
			<img
				ngSrc="./assets/images/key-mini.png"
				height="50"
				width="50"
				class="access-denied-card-image-img"
				alt="img d'accès refusé" />
		</div>
		<div class="access-denied-card-title">Un grand pouvoir implique de grandes responsabilités</div>
		<div class="access-denied-card-text">Merci de contactez votre responsable pour effectuer une demande d'accès à l'intranet.</div>
	</div>
</div>
