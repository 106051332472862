import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { appNavigation, gestionNavigation, navigation } from '../../application/navigation/config';
import { Router, RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NavigationItemComponent } from './navigation-item/navigation-item.component';
import { map, switchMap, takeUntil } from 'rxjs';
import { select } from '@ngneat/elf';
import {
	faChevronLeft,
	faChevronRight,
	faGear,
	faHouse,
	faHome,
	faRightToBracket,
	IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { SelectAgencyComponent } from './select-agency/select-agency.component';
import { BaseComponent, DropdownComponent } from '@components';
import { Agency, AuthenticatedUserData, preference } from '@domain';
import { OidcService } from '@infrastructure';
import { AuthenticationManager, PreferenceManager } from '@application';


@Component({
	selector: 'intranet-navigation',
	standalone: true,
	imports: [CommonModule, NgOptimizedImage, RouterLink, FaIconComponent, NavigationItemComponent, DropdownComponent, SelectAgencyComponent],
	templateUrl: './navigation.component.html',
	styleUrl: './navigation.component.scss',
})
export class NavigationComponent extends BaseComponent implements OnInit {
	readonly faHouse: IconDefinition = faHouse;
	public faRightBracket: IconDefinition = faRightToBracket;
	public faChevronRight: IconDefinition = faChevronRight;
	public agencies: Agency[] = [];

	profile: AuthenticatedUserData | null = null;
	currentAgency: Agency | null = null;
	navigation: navigation[] = [];
	gestionNavigation: navigation[] = [];
	navbarIsCollapsed = false;

	constructor(
		private readonly oidcService: OidcService,
		private readonly prefManager: PreferenceManager,
		protected readonly authenticationManager: AuthenticationManager,
		private router: Router,
	) {
		super();
	}

	get iconState(): IconDefinition {
		return this.navbarIsCollapsed ? faChevronRight : faChevronLeft;
	}

	logout() {
		this.oidcService.logout();
	}

	collapsed() {
		this.navbarIsCollapsed = !this.navbarIsCollapsed;

		const preferences: preference = {
			navigation: {
				isCollapsed: this.navbarIsCollapsed,
			},
			agency: this.prefManager.store.value.preference ? this.prefManager.store.value.preference.agency : null,
		};

		this.prefManager.updatePreference(JSON.stringify(preferences));
	}

	public selectAgency(agency: Agency) {
		this.authenticationManager.changeCurrentUserAgency(agency);

		const preferences: preference = {
			navigation: {
				isCollapsed: this.prefManager.store.value.preference && this.prefManager.store.value.preference.navigation ? this.prefManager.store.value.preference.navigation.isCollapsed : false,
			},
			agency: agency,
		};
		this.prefManager.updatePreference(JSON.stringify(preferences));
		this.router.navigate(['/home']);
	}

	ngOnInit() {
		this.prefManager.getUserPreference();

		// SUBSCRIBE TO USER
		this.subscribeUser();

		// SUBSCRIBE USER MODULES
		this.subscribeModules();

		// SUBSCRIBE TO USER CURRENT AGENCY
		this.subscribeAgency();

		// SUBSCRIBE TO USER AGENCIES
		this.subscribeAgencies();
	}

	private subscribeUser() {
		this.authenticationManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				select(state => state.authenticatedUserData),
			)
			.subscribe(user => {
				if (user) {
					this.profile = user;
				}
			});
	}

	private subscribeAgency() {
		this.authenticationManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				select(state => state.currentUserAgency),
				map(agency => {
					if (agency) {
						this.currentAgency = agency;
					}
				}),
			)
			.subscribe();
	}

	private subscribeAgencies() {
		this.authenticationManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				select(state => state.agencies),
				switchMap(agencies => {
					if (agencies) {
						this.agencies = agencies.sort((a, b) => a.name.localeCompare(b.name));
					}
					return this.subscribePreferences();
				}),
			)
			.subscribe();
	}

	private subscribePreferences() {
		return this.prefManager.store.pipe(
			takeUntil(this.$unsubscribe),
			select(state => state.preference),
			map(data => {
				if (data && data.navigation) {
					this.navbarIsCollapsed = data.navigation.isCollapsed;
				}
				this.currentAgency = data?.agency && this.agencies.length > 0 ? data.agency : this.currentAgency;
			}),
		);
	}

	private subscribeModules() {
		this.authenticationManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				select(state => state.userModules),
			)
			.subscribe(modules => {
				// IF USER HAS SOME MODULES
				if (modules != null && modules.length > 0) {
					let allowedAppNav: navigation[] = [];
					let allowedGestionNav: navigation[] = [];

					// BUILD APP NAVIGATION
					// LOOP ON MODULES
					modules.forEach(module => {
						let foundAppNav = appNavigation.find(nav => nav.slug == module.slug);
						let foundGestionNav = gestionNavigation.find(nav => {
							return nav.slug == module.slug || nav.slug == '';
						});

						// IF NAVIGATION MODULE IS PRESENT FROM USER MODULE DISPLAY IT
						if (foundAppNav != undefined) {
							allowedAppNav.push(foundAppNav);
						}
						// IF NAVIGATION MODULE IS PRESENT FROM USER MODULE DISPLAY IT
						if (foundGestionNav != undefined) {
							allowedGestionNav.push(foundGestionNav);
						}
					});
					// SET THE ALOOWED NAVS
					this.navigation = allowedAppNav;
					this.navigation.unshift(
						{
							path: 'home',
							label: 'Accueil',
							icon: faHome,
							slug: '',
						}
					)
					this.gestionNavigation = allowedGestionNav.concat({
						path: 'parametres',
						label: 'Paramètres',
						icon: faGear,
						slug: '',
					})
				}
			});
	}
}
