import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { devTools } from '@ngneat/elf-devtools';
import { ApplicationRef, EnvironmentInjector } from '@angular/core';
import { Settings } from 'luxon';

devTools();
export let injector: EnvironmentInjector | null = null;
bootstrapApplication(AppComponent, appConfig)
	.catch(err => console.error(err))
	.then(res => {
		const ref = res as ApplicationRef;
		injector = ref.injector;
		Settings.defaultLocale = 'fr';
	});
