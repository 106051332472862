import { Component, OnInit } from '@angular/core';
import { select } from '@ngneat/elf';
import DoctorManager from './application/doctor/doctor.manager';
import AgencyManager from './application/agency/agency.manager';
import { NavigationComponent } from './components/navigation/navigation.component';
import { RouterOutlet } from '@angular/router';
import { TypeInterventionManager } from './application/intervention/type-intervention.manager';
import { appRoutes } from './app.routes';
import { map, of, skipWhile, switchMap, takeUntil } from 'rxjs';
import { BaseComponent } from '@components';
import { Agency } from '@domain';
import { AuthenticationManager, PreferenceManager, ToasterManager } from '@application';
import { AnalyticService } from 'core/application/src/lib/analytics/analytics.service';
import { environment } from '../environments/environment';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
	standalone: true,
	imports: [NavigationComponent, RouterOutlet, FaIconComponent],
	selector: 'intranet-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent extends BaseComponent implements OnInit {
	appRoutes = appRoutes;

	private agencies: Agency[] = [];

	protected readonly faExclamationTriangle = faExclamationTriangle;

	ngOnInit(): void {
		// INIT THE TOASTER
		this.toasterManager.init();

		this.subscribeEmployeeIds();

		// SUBSCRIBE TO USER PROFILE
		this.subscribeProfile();

		// SUBSCRIBE TO USER AGENCIES
		this.subscribeUserAgencies();

		// CHECK IF USER IS AUTHENTICATED
		this.authenticationManager.checkAuthentication();
	}

	constructor(
		protected readonly authenticationManager: AuthenticationManager,
		private readonly agencyManager: AgencyManager,
		private readonly toasterManager: ToasterManager,
		private readonly doctorManager: DoctorManager,
		private readonly typeInterventionManager: TypeInterventionManager,
		private readonly preferenceManager: PreferenceManager,
		private analyticsService: AnalyticService,
	) {
		super();
	}

	private subscribeUserAgencies() {
		this.preferenceManager.getUserPreference().subscribe();

		this.authenticationManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				skipWhile(state => state.agencies == null),
				select(state => state.agencies),
				switchMap(agencies => {
					this.agencies = agencies ? agencies : [];
					if (agencies && agencies.length > 0) {
						return this.preferenceManager.store.pipe(select(state => state.preference));
					} else {
						return of(null);
					}
				}),
				map(preference => {
					if (preference && preference.agency) {
						return preference.agency;
					} else {
						return this.agencies ? this.agencies[0] : null;
					}
				}),
			)
			.subscribe(agency => {
				// IF USER ALREADY HAS A PREFERED AGENCY --> RETRIEVE THE MODULES IF IT
				if (agency != null) {
					this.retrieveUserModules(agency);
				}

				// USER HAS NO PREFERED AGENCY
				else {
					// IF USER HAS AGENCIES --> RETRIEVE THE MODULE OF THE FIRST ONE TO DISPLAY THE RELATED MENU
					if (this.agencies && this.agencies.length > 0) {
						this.retrieveUserModules(this.agencies[0]);
					}
					// IF USER HAS NO AGENCIES --> RETRIEVE THE USER MODULES WITHOUT PASSING THE AGENCY TO DIPSLAY THE MENU FOR THE SUPER ADMIN
					if (this.agencies.length === 0) {
						// RETRIEVE THE USER MODULES
						this.authenticationManager.retrieveUserModules(undefined);
					}
				}
			});
	}

	private subscribeProfile() {
		this.authenticationManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				select(state => state.authenticatedUserData),
			)
			.subscribe(user => {
				// WHEN USER AUTHENTICATED
				if (user != null) {
					// INITIALIZE ANALYTICS
					this.analyticsService.initializeInsights(environment.analyticsIntrumentationKey, user.userId, user.azureId);

					// RETRIEVE IT AGENCIES
					this.authenticationManager.retrieveUserAgencies();

					// GET TYPE INTERVENTIONS AND DOCTOR SPECIALTIES
					this.agencyManager.defineAvailableAgencies();
					this.typeInterventionManager.getTypeIntervention();
					this.doctorManager.getDoctorSpecialties();
				}
			});
	}

	private retrieveUserModules(agency: Agency) {
		// SET THE AGENCY PREFERENCE
		this.authenticationManager.store.update(state => {
			return {
				...state,
				currentUserAgency: agency,
			};
		});

		// RETRIEVE THE EMPLOYEE IDS
		this.authenticationManager.retrieveEmployeeIds();

		// RETRIEVE THE USER MODULES FOR THIS AGENCY
		this.authenticationManager.retrieveUserModules(agency.id);
	}

	private subscribeEmployeeIds() {
		this.authenticationManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				skipWhile(employeeIds => employeeIds == null),
				select(state => state.employeeIds),
			)
			.subscribe(employeeIds => {
				// GET THE CURRENT EMPLOYEE ID OF THE CURRENT AGENCY
				const currentEmployeeRef = employeeIds!.find(ids => ids.agencyId! == this.authenticationManager!.store!.value!.currentUserAgency!.id!);

				this.authenticationManager.store.update(state => {
					return {
						...state,
						currentEmployeeId: currentEmployeeRef!.employeeId,
					};
				});
			});
	}
}
