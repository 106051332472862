import { Component, EventEmitter, input, InputSignal, Output, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { ClickOutsideDirective } from '@components';
import { Agency } from '@domain';


@Component({
	selector: 'intranet-select-agency',
	standalone: true,
	imports: [CommonModule, FaIconComponent, ClickOutsideDirective],
	templateUrl: './select-agency.component.html',
	styleUrl: './select-agency.component.scss',
})
export class SelectAgencyComponent {
	public isCollapsed: InputSignal<boolean> = input.required<boolean>();
	public currentAgencyName: InputSignal<Agency | null> = input.required<Agency | null>();
	public agencies: InputSignal<Agency[]> = input.required<Agency[]>();
	@Output() agencySelected: EventEmitter<Agency> = new EventEmitter<Agency>();

	public faBuilding = faBuilding;
	public faChevronDown = faChevronDown;
	public faChevronUp = faChevronUp;
	public listIsVisible: WritableSignal<boolean> = signal(false);

	public toggleList(event: Event) {
		if (this.agencies().length > 1) {
			this.listIsVisible.set(!this.listIsVisible());
		}
		event.stopPropagation();
	}

	public selectListItem(agency: Agency) {
		this.agencySelected.emit(agency);
		this.toggleList(new Event('click'));
	}
}
