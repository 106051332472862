import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';
import { appRoutes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AbstractSecurityStorage, AuthInterceptor, LogLevel, provideAuth } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { provideToastr } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpErrorsInterceptor, LoadingListenerInterceptor, OidcCustomRepository } from '@infrastructure';
import { AzureErrorHandlerService } from '../../../../core/application/src/lib/analytics/analyticsErrorHandler.service';

export const appConfig: ApplicationConfig = {
	providers: [
		provideToastr({
			maxOpened: 1,
			preventDuplicates: true,
		}),
		provideAnimations(),
		provideAuth({
			config: {
				autoUserInfo: false,
				redirectUrl: environment.redirectUrl,
				authority: environment.security,
				clientId: environment.clientId,
				scope: environment.authenticationScope,
				responseType: 'code',
				silentRenew: true,
				useRefreshToken: true,
				ignoreNonceAfterRefresh: true,
				triggerRefreshWhenIdTokenExpired: false,
				secureRoutes: [environment.authorizedUr],
				maxIdTokenIatOffsetAllowedInSeconds: 600,
				logLevel: LogLevel.Error,
			},
		}),
		provideHttpClient(withInterceptorsFromDi()),
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoadingListenerInterceptor, multi: true },
		{ provide: AbstractSecurityStorage, useClass: OidcCustomRepository, multi: false },
		{ provide: ErrorHandler, useClass: AzureErrorHandlerService },
		provideRouter(
			appRoutes,
			withRouterConfig({
				paramsInheritanceStrategy: 'always',
				onSameUrlNavigation: 'reload',
			}),
		),

	],
};
