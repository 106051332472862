<div class="application-container">

	<div class="navigation-container">
		@if (authenticationManager.store.state.access) {
			<intranet-navigation></intranet-navigation>
		}
	</div>

	<main class="main-container">

		<div class="temp-alert">
			<div class="temp-alert-title">
				<h1>
					<fa-icon [icon]="faExclamationTriangle"></fa-icon>
					Site en construction
				</h1>
			</div>
			<div class="temp-alert-body">
				<p>
					Ce site est en cours de construction, nous vous recommandons uniquement l'utilisation de la
					partie "Documents" et "Patients>Documents". Nous vous tiendrons informés des prochaines fonctionnalités
					disponibles à l'utilisation.
				</p>
			</div>
		</div>

		<div class="content">
			<router-outlet></router-outlet>
		</div>

	</main>

</div>
