<div class="select-agency">
	<div
		class="select-agency-label"
		[ngClass]="isCollapsed() ? 'select-agency-label-collapsed' : 'select-agency-label-open'"
		[style.cursor]="agencies().length > 1 ? 'pointer' : 'default'"
		(click)="toggleList($event)">
		<fa-icon [icon]="faBuilding"></fa-icon>
		@if (!isCollapsed()) {
			<p class="select-agency-label-agency">
				@if (currentAgencyName() !== undefined && currentAgencyName() !== null) {
					{{ currentAgencyName()?.name | slice: 0 : 15 }}
					<div class="select-agency-label-agency-chevrons">
						@if (agencies().length > 1) {
							@if (listIsVisible()) {
								<fa-icon
									class="select-agency-label-agency-chevrons-icon"
									[icon]="faChevronUp"></fa-icon>
							} @else {
								<fa-icon
									class="select-agency-label-agency-chevrons-icon"
									[icon]="faChevronDown"></fa-icon>
							}
						}
					</div>
				} @else {
					Aucune agence
				}
			</p>
		}
	</div>

	<div
		class="select-agency-list"
		[ngClass]="[isCollapsed() ? 'select-agency-list-collapsed' : '', !listIsVisible() ? 'select-agency-list-unvisible' : '']">
		<div class="ul-block">
			<ul
				intranetClickOutside
				(outSideClick)="listIsVisible.set(false)"
				[appOutSideClick]="listIsVisible()">
				@for (item of agencies(); track item.name) {
					<li
						(click)="selectListItem(item)"
						class="select-list_item">
						{{ item.name | slice: 0 : 22 }}
					</li>
				}
			</ul>
		</div>
	</div>
</div>
