import { Component, input, InputSignal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ClickOutsideDirective } from '@components';
import { navigation } from '../../../application/navigation/config';


@Component({
	selector: 'intranet-navigation-item',
	standalone: true,
	imports: [CommonModule, RouterLink, FaIconComponent, RouterLinkActive, ClickOutsideDirective],
	templateUrl: './navigation-item.component.html',
	styleUrl: './navigation-item.component.scss',
})
export class NavigationItemComponent implements OnInit {
	item: InputSignal<navigation> = input.required<navigation>();
	isCollapsed: InputSignal<boolean> = input.required<boolean>();

	displaySubNavigation = false;

	get icon() {
		return this.item().icon as IconDefinition;
	}

	get stateIcon() {
		return this.displaySubNavigation ? faChevronUp : faChevronDown;
	}

	openSubNavigation() {
		if (this.item().subNavigation) {
			this.displaySubNavigation = !this.displaySubNavigation;
		}
	}

	ngOnInit() {
		if (this.item().isOpen) {
			this.displaySubNavigation = this.item().isOpen as boolean;
		}
	}
}
