import { ErrorHandler, Injectable } from '@angular/core';
import { AnalyticService } from './analytics.service';


@Injectable({
	providedIn: 'root',
})
export class AzureErrorHandlerService extends ErrorHandler {

    constructor(private analyticsService: AnalyticService ) {
        super();
    }

    override handleError(error: Error) {
        this.analyticsService.logException(error); // Manually log exception
    }
}