import { faCube, faKey, faUser, faCalendarAlt, faUserDoctor, IconDefinition, faFolderOpen, faHome } from '@fortawesome/free-solid-svg-icons';

export type navigation = {
	path: string | null;
	label: string;
	icon: IconDefinition | null;
	isOpen?: boolean;
	subNavigation?: navigation[];
	slug: string;
};

export const appNavigation: navigation[] = [
	{
		path: 'home',
		label: 'Accueil',
		icon: faHome,
		slug: '',
	},
	{
		path: 'patient',
		label: 'Patients',
		icon: faUser,
		slug: 'Patients',
	},
	{
		path: 'doctors',
		label: 'Docteurs',
		icon: faUserDoctor,
		slug: 'Docteurs',
	},
	{
		path: null,
		label: 'Gestion des stocks',
		icon: faCube,
		isOpen: false,
		slug: 'GestionDesStocks',
		subNavigation: [
			{
				path: 'stocks/store',
				label: 'Magasins',
				icon: null,
				slug: '',
			},
			{
				path: 'stocks/template-store',
				label: 'Magasins modèles',
				icon: null,
				slug: '',
			},
			{
				path: 'stocks/medical-device',
				label: 'Dispositifs médicaux',
				icon: null,
				slug: '',
			},
			{
				path: 'stocks/fia',
				label: 'Fiches articles',
				icon: null,
				slug: '',
			},
			{
				path: 'stocks/order',
				label: 'Créer une commande',
				icon: null,
				slug: '',
			}			
		],
	},
	{
		path: 'planning-tech',
		label: 'Planning techniciens',
		icon: faCalendarAlt,
		slug: 'PlanningTechniciens',
	},
	{
		path: 'documents-techniciens',
		label: 'Documents',
		icon: faFolderOpen,
		slug: 'DocumentsTechniciens',
	},
];

export const gestionNavigation: navigation[] = [
	{
		path: 'user',
		label: 'Accès utilisateurs',
		icon: faKey,
		slug: 'AccesUtilisateur',
	},
];
