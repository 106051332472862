<aside
	[ngClass]="navbarIsCollapsed ? 'collapsed' : 'open'"
	class="sos-navbar">
	<div class="sos-navbar_logo">
		@if (!navbarIsCollapsed) {
			<img
				ngSrc="./assets/images/logo.svg"
				height="50"
				width="50"
				alt="logo oxyweb"
				class="hover"
				routerLink="/home" />
			<h3 routerLink="/home" class="hover">Oxyweb</h3>
		}

		<button
			(click)="collapsed()"
			[ngClass]="navbarIsCollapsed ? 'center' : ''"
			class="btn-sos btn-medium">
			<fa-icon [icon]="iconState"></fa-icon>
		</button>

	</div>

	<div class="sos-navbar_profile">
		<intranet-select-agency
			(agencySelected)="selectAgency($event)"
			[agencies]="agencies"
			[currentAgencyName]="currentAgency"
			[isCollapsed]="navbarIsCollapsed">
		</intranet-select-agency>
	</div>

	<div class="sos-navbar_navigation">
		<ul class="navigation-list">
			@for (item of navigation; track item.label) {
				<intranet-navigation-item
					[isCollapsed]="navbarIsCollapsed"
					[item]="item">
				</intranet-navigation-item>
			}
		</ul>
	</div>

	<div class="sos-navbar_parametre">

		<ul class="navigation-params-list">
			@for (item of gestionNavigation; track item.label) {
				<intranet-navigation-item
					[isCollapsed]="navbarIsCollapsed"
					[item]="item">
				</intranet-navigation-item>
			}
		</ul>

		<button
			(click)="logout()"
			[ngClass]="navbarIsCollapsed ? 'center' : ''"
			class="btn-sos btn-medium">
			@if (!navbarIsCollapsed) {
				<span>Déconnexion</span>
			}
			<fa-icon [icon]="faRightBracket"></fa-icon>
		</button>
	</div>
</aside>
