import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'intranet-acces-denied',
	standalone: true,
	imports: [CommonModule, NgOptimizedImage],
	templateUrl: './access-denied.component.html',
	styleUrls: ['./access-denied.component.scss'],
})
export class AccessDeniedComponent {
	constructor() {}
}
