<div class="container">
	<h3>Accueil</h3>
	<div class="flex">


		<!-- ORTHOP CARD -->
		<div class="card hover mt-large" (click)="goOrhtopErrors()"
			[hasPermission]="{ permissionSlug: permissionTypes.ORTHOP_CARD, permissionType: 'read' }">
			<p class="card-header">Erreur@if( nbOrthopErrors! > 1){<span>s</span>}
				Orthop 
			</p>
			<span class="ml-auto">Dernière synchro: {{lastSyncDate ? lastSyncDate : '-'}}</span>

			<div class="flex">
				@if(nbOrthopErrors != null ){
				<p class="card-main-info align-self-center">{{ nbOrthopErrors }}</p>
				}
				@else{
				<div class="spinner-small align-self-center"></div>
				}
				<fa-icon size="4x" class="warning-icon ml-auto" [icon]="icons.faExclamationTriangle"></fa-icon>

			</div>
			<p class="card-sub-info mt-small">Nombre d'erreurs lors de la synchronisation avec Orthop</p>
		</div>

		<!-- AMC ERROR CARD -->
		<div class="card hover mt-large ml-large" (click)="goAmcErrors()"
			[hasPermission]="{ permissionSlug: permissionTypes.AMC_CARD, permissionType: 'read' }">
			<p class="card-header">Erreur@if( nbAmcErrors! > 1){<span>s</span>}
				de traitement AMC
			</p>

			<div class="flex">
				@if(nbAmcErrors != null ){
				<p class="card-main-info align-self-center">{{ nbAmcErrors }}</p>
				}
				@else{
				<div class="spinner-small align-self-center"></div>
				}
				<fa-icon size="4x" class="warning-icon ml-auto" [icon]="icons.faExclamationTriangle"></fa-icon>

			</div>
			<p class="card-sub-info mt-small">Nombre d'erreurs de traitement des cartes mutuelles par l'IA</p>
		</div>
	</div>
</div>