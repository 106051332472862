import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { BaseComponent, BreadcrumbComponent, HasPermissionDirective } from '@components';
import * as icons from '@fortawesome/free-solid-svg-icons';
import DashboardManager from '../../application/dashboard/dashboard.manager';
import { select } from '@ngneat/elf';
import { AuthenticationManager, permissionType } from '@application';
import { map, skipWhile, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { searchAmcErrorParams, searchOrthopErrorParams } from '../../domain/dashboard/types/dashboard-manager.types';
import { DateTime } from 'luxon';



@Component({
	selector: 'intranet-home',
	standalone: true,
	imports: [
		CommonModule,
		FaIconComponent,
		BreadcrumbComponent,
		HasPermissionDirective
	],
	templateUrl: './home.component.html',
	styleUrl: './home.component.scss',
})
export class HomeComponent extends BaseComponent implements OnInit {

	icons = icons;
	public nbOrthopErrors: number | null = null;
	public nbAmcErrors: number | null = null;

	public permissionTypes = permissionType;
	public lastSyncDate: string | null = null;


	constructor(
		private dashboardpManager: DashboardManager,
		private authenticatedManager: AuthenticationManager,
		private router: Router
	) {
		super();
	}

	ngOnInit() {
		this.subscribeAgency();
		this.subscribeOrthopErrors()
		this.subscribeAMCErrors()
		this.subscribeLastSyncDateOrthop();
		this.subscribeAgency();

	}

	// RETRIEVE THE ORHTOP ERRORS
	private subscribeOrthopErrors() {
		this.dashboardpManager.store.pipe(
			takeUntil(this.$unsubscribe),
			select(state => state.orthopErrors),
			skipWhile(errors => errors.data == null),
		).subscribe(errors => {
			this.nbOrthopErrors = errors.pagination.total;
		});
	}

	// RETRIEVE THE AMC ERRORS
	private subscribeAMCErrors() {
		this.dashboardpManager.store.pipe(
			takeUntil(this.$unsubscribe),
			select(state => state.amcErrors),
			skipWhile(errors => errors.data == null),
		).subscribe(errors => {
			this.nbAmcErrors = errors.pagination.total;
		})
	}

	// LISTEN CURRENT AGENCY
	private subscribeCurrentAgency() {
		this.authenticatedManager.store.pipe(
			takeUntil(this.$unsubscribe),
			select(state => state.currentUserAgency),
			skipWhile(agency => agency == null),
		).subscribe(agency => {
			this.ngOnInit();
		})
	}

	private subscribeLastSyncDateOrthop() {
		this.dashboardpManager.store.pipe(
			takeUntil(this.$unsubscribe),
			select(state => state.lastOrthopSyncDate),
			skipWhile(date => date == null),
		).subscribe(date => {
			this.lastSyncDate = date != "" ? DateTime.fromISO(date!).toLocaleString(DateTime.DATETIME_SHORT) : null;
		})
	}

	// RETRIEVE THE CURRENT AGENCY ID
	private subscribeAgency() {
		this.authenticatedManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				select(state => state.currentUserAgency),
				skipWhile(agency => agency == null),
				map(agency => {
					// RETRIEVE THE ORTHOP ERRORS
					this.retrieveOrthopErrors(agency?.id!)

					// RETRIEVE THE LAST SYNC DATE OF ORTHOP ERRORS
					this.dashboardpManager.retrieveLastSyncDateOrthop(agency!.id);

					// RETRIEVE THE AMC ERRORS
					this.retrieveAMCErrors(agency!.id!)
				}),
			)
			.subscribe();
	}

	// PERFORM THE SERVER CALL TO RETRIEVE ORTHOP ERRORS
	private retrieveOrthopErrors(agencyId: number) {
		let params: searchOrthopErrorParams = {
			agencyId: agencyId,
			pageNumber: 1,
			pageSize: 10
		}

		this.dashboardpManager.retrieveOrthopErrors(params);
	}

	// PERFORM THE SERVER CALL TO RETRIEVE AMC ERRORS
	private retrieveAMCErrors(agencyId: number) {
		let params: searchAmcErrorParams = {
			agencyId: this.authenticatedManager.store.value.currentUserAgency?.id!,
			pageNumber: 1,
			pageSize: 10
		}

		this.dashboardpManager.retrieveAmcErrors(params);
	}

	public goOrhtopErrors() {
		this.router.navigate(["dashboard/orthop"])
	}


	public goAmcErrors() {
		this.router.navigate(["dashboard/amc"])
	}

}
